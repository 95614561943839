import React from "react";
import Box from "components/box";
import Layout from "components/layout";

const NotFound = () => (
  <Layout>
    <Box>Not found.</Box>
  </Layout>
);

export default NotFound;
